import React from "react";

const can = (permission, action) => {
	if (typeof window.data.admin !== "undefined") {
		return true;
	}

	if (typeof window.data.user !== "undefined" && typeof window.data.user.permissions !== "undefined") {
		const { permissions } = window.data.user;

		if (typeof permissions !== "undefined") {
			if (Object.prototype.hasOwnProperty.call(permissions, permission)) {
				return permissions[permission] === true || permissions[permission].includes(action);
			} else {
				return false;
			}
		}
	}

	if (
		typeof window.data.contactperson !== "undefined" &&
		typeof window.data.contactperson.permissions !== "undefined"
	) {
		const { permissions } = window.data.contactperson;

		if (typeof permissions !== "undefined") {
			if (Object.prototype.hasOwnProperty.call(permissions, permission)) {
				return permissions[permission] === true || permissions[permission].includes(action);
			} else {
				return false;
			}
		}
	}

	return false;
};

const slugify = value => {
	const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
	const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------";
	const p = new RegExp(a.split("").join("|"), "g");

	return value
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-") // Replace spaces with -
		.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, "-and-") // Replace & with 'and'
		.replace(/[^\w-]+/g, "") // Remove all non-word characters
		.replace(/--+/g, "-") // Replace multiple - with single -
		.replace(/^-+/, "") // Trim - from start of text
		.replace(/-+$/, ""); // Trim - from end of text
};

const route = (route, data) => window.route(route, data, true, window.Ziggy);

const urlHttpPrefix = url => {
	if (!/^https?:\/\//i.test(url)) {
		return `http://${url}`;
	}
	return url;
};

function ucFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function validateEmail(email) {
	// eslint-disable-next-line no-useless-escape
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

function numberFormat(number, decimals = 0) {
	return Number(number).toLocaleString("NL-nl", {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	});
}

import { uctrans } from "./lib/Translator";

const yesNoOptions = (addShowAll = true) => {
	const defaultOptions = [
		{ value: "1", label: uctrans("general.yes") },
		{ value: "0", label: uctrans("general.no") },
	];

	if (addShowAll) {
		return [...[{ value: "", label: uctrans("overview.show_all") }], ...defaultOptions];
	}

	return defaultOptions;
};

const textCopyHelper = copy_text => {
	navigator.clipboard.writeText(copy_text);
};

const isFragment = variableToInspect => {
	if (variableToInspect.type) {
		return variableToInspect.type === React.Fragment;
	}
	return variableToInspect === React.Fragment;
};

const removeEmpty = obj => {
	Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
	return obj;
};

export {
	can,
	route,
	urlHttpPrefix,
	slugify,
	ucFirst,
	validateEmail,
	numberFormat,
	yesNoOptions,
	textCopyHelper,
	isFragment,
	removeEmpty,
};
